.programs__links{
    border-bottom: 2px solid;
    border-image: linear-gradient(to right, #007ab3 25%, #140066 25%, #54002a 50%,#eb3300 50%, #8bcc00 75%, teal 75%) 5;
    background-color: rgb(0, 0, 0,0.3);
}
.programs__link{
    
    text-decoration: none;
    color:whitesmoke;
    &:hover{
        text-decoration: underline;
        color: darken($color: whitesmoke, $amount: 20);

    }
}

.programs__link-menu{
    &:hover{
        background-color:  white;
    }
}

.programs__logo{
    box-shadow:  $box-shadow-sm;
}
.programs__logo:hover{
    box-shadow: $box-shadow;
    -webkit-transition: box-shadow 300ms;
    -ms-transition: box-shadow 300ms ;
    transition: box-shadow 300ms;
}