.events__banner {
  margin-top: 200px;
}

.events__links {
  border-bottom: 2px solid;
  border-image: linear-gradient(
      to right,
      #007ab3 25%,
      #140066 25%,
      #54002a 50%,
      #eb3300 50%,
      #8bcc00 75%,
      teal 75%
    )
    5;
  background-color: rgb(0, 0, 0, 0.3);
}
.events__link {
  text-decoration: none;
  color: whitesmoke;
  &:hover {
    text-decoration: underline;
    color: darken($color: whitesmoke, $amount: 20);
  }
}

.events__link-menu {
  &:hover {
    background-color: white;
  }
}

.events__logo {
  box-shadow: $box-shadow-sm;
}
.events__logo:hover {
  box-shadow: $box-shadow;
  -webkit-transition: box-shadow 300ms;
  -ms-transition: box-shadow 300ms;
  transition: box-shadow 300ms;
}

.events__actividades {
  line-height: 1.8em;
  list-style-position: inside;
  margin: 0;
  padding: 8px 0 8px 20px;
}
.events__actividades_item {
    display: inline-block;
  }
  