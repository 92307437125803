.contact__banner-image{
    background-image: url('../../assets/imgs/contact-us.JPG');
    background-size: cover;
}

.contact__input {
    color: rgb(114, 114, 114);
}

.contact__bg-contact{
  background-color: #f4f4f4;
}

.contact__link{
  text-decoration: none;
    color:gray;
    &:hover{
        text-decoration: underline;
        color: darken($color: gray, $amount: 20);

    }
}

.contact__card{
  width: 100%;
  height: 100%;
}