.footer__bg-footer {
    background-color: #f0f0f0;
  }
  
  .footer__bgs-footer {
    background-color: #e7e7e7;
  }

  .footer__icons{
    font-size: 32px;
    color: #919191;
  }
  