.navbar__bg-nav-in{
    background-color: #a41217;
    -webkit-transition: background-color 500ms;
    -ms-transition: background-color 500ms ;
    transition: background-color 500ms;
}

.navbar__bg-nav-out{
    background-color: transparent;
    -webkit-transition: background-color 300ms;
    -ms-transition: background-color 300ms ;
    transition: background-color 300ms;
}