.gallery__grid {
  display: grid;
  grid-gap: 1rem;
  justify-items: center;
}

@media all and (min-width: 320px) {
  .gallery__grid {
    grid-gap: 1rem;
  }
}

@media all and (min-width: 768px) {
  .gallery__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media all and (min-width: 1024px) {
  .gallery__grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

.gallery__image{
  object-fit: cover;
  height: 100%;
  box-shadow:  $box-shadow-sm;
  &:hover{
    box-shadow: $box-shadow;
    -webkit-transition: box-shadow 300ms;
    -ms-transition: box-shadow 300ms ;
    transition: box-shadow 300ms;
  }
}

.card-swadow{
  box-shadow:  $box-shadow-sm;
  &:hover{
    box-shadow: $box-shadow;
    -webkit-transition: box-shadow 300ms;
    -ms-transition: box-shadow 300ms ;
    transition: box-shadow 300ms;
  }
}

// .gallery__image{
//     object-fit: cover;
//     cursor: pointer;
//     box-shadow:  $box-shadow-sm;
//     height: 100%;
// }

// .gallery__image:hover{
//     box-shadow: $box-shadow;
//     -webkit-transition: box-shadow 300ms;
//     -ms-transition: box-shadow 300ms ;
//     transition: box-shadow 300ms;
// }
//         .grid-gallery {
//           display: grid;
//           grid-gap: 1rem;
//           grid-auto-flow: row dense;
//         }

//         @media all and (min-width: 320px) {
//           .grid-gallery {
//             grid-template-columns: repeat(2, 1fr);
//           }
//         }

//         @media all and (min-width: 768px) {
//           .grid-gallery {
//             grid-template-columns: repeat(3, 1fr);
//           }
//         }

//         @media all and (min-width: 1024px) {
//           .grid-gallery {
//             grid-template-columns: repeat(6, 1fr);
//           }
//         }

//         .grid-gallery__item:nth-child(11n+1) {
//           grid-column: span 1;
//         }

//         .grid-gallery__item:nth-child(11n+4) {
//           grid-column: span 2;
//           grid-row: span 2;
//         }

//         .grid-gallery__item:nth-child(11n+6) {
//           grid-column: span 2;
//           grid-row: span 2;
//         }

//         .grid-gallery__item:nth-child(11n+7) {
//           grid-column: span 2;
//           grid-row: span 2;
//         }

//         .grid-gallery__item:nth-child(11n+8) {
//           grid-column: span 2;
//           grid-row: span 2;
//         }

//         .grid-gallery__item:nth-child(11n+9) {
//             grid-column: span 2;
//             grid-row: span 2;
//         }

//         .grid-gallery__image {
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//         }
