.home__p-item{
    cursor: pointer;
    background-color:#e3e3e3;
    border-radius: 3;
    color: #808080;

    &:hover{
            color: white;
            background-color: #0D6EFD;
            border-radius: 3;    
            transition-duration: 300ms;  
    }

}

.home__p-item__outline{
    cursor: pointer;
    border-radius: 3;
    color: #808080;
    background-color:#e3e3e3;

    &:hover{
            color: white;
            background-color: #0D6EFD;
            border-radius: 3;    
            transition-duration: 300ms;  
    }

}


.home__p-item-click{
    color: white;
    background-color: #0D6EFD;
    border-radius: 3;
    cursor: pointer;
}


.home__bg-div{
    background-color:#e3e3e3;
    border-color: #e3e3e3;
}

.home__bg-button{
    background-color:#e3e3e3;
    border-color: #e3e3e3;
    color: #808080;
}


.home__banner-image.lazy{
   background-image: none;
   background-color: #000;
}
.home__banner-image {
    background-image: url('../../assets/imgs/background.JPG');
    background-size: cover;
}

.home__about-image {
    background-image: url('../../assets/videos/about_us.gif');
    background-size: cover;
}

.home__borroso {
    background-color: rgb(0, 0, 0,0.3);
}

.home__custom-donate{
//   margin-top: 50px;

  @media screen and (max-width: 576px) {
    margin-top: 50px;
  }
  
}

.home__goback{
    cursor: pointer;
}