
// Fuente strong
@font-face {
  font-family: "ITC Kabel Std Bold";
  font-display: swap;
  src: url("../../assets/fonts/ITCKabelStd-Demi.otf");
}

// Fuente normal
@font-face {
  font-family: "ITC Kabel Std Normal";
  font-display: swap;
  src: url("../../assets/fonts/ITCKabelStd-Book.otf");
}

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "ITC Kabel Std Bold";
}

.fancy-border {
  border-top: 3px solid;
  border-radius: 4px;
  border-image: linear-gradient(to right, #007ab3 25%, #140066 25%, #54002a 50%,#eb3300 50%, #8bcc00 75%, teal 75%) 5;
}

.fancy-border-bottom {
  border-bottom: 3px solid;
  border-radius: 4px;
  border-image: linear-gradient(to right, #007ab3 25%, #140066 25%, #54002a 50%,#eb3300 50%, #8bcc00 75%, teal 75%) 5;
}

.bg-borroso {
  background-color: rgb(0, 0, 0,0.4);
}

.fs-normal{
  font-family: "ITC Kabel Std Normal";
}

.text-justify{
  text-align: justify;
}

.coming-soon-image {
  background-image: url('https://cdn.pixabay.com/photo/2019/12/27/08/36/coming-soon-hour-glass-4721933_1280.png');
  background-size: cover;
}

.custom-spinner{
   width: 3rem;
   height: 3rem;
}

.custom-loading{
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-green{
  color: #29B473;
}

.bg-green{
  background-color: #29B473;
}

optgroup[label] {
  font-style: inherit;
  font-weight: 300;
  text-shadow: none
}