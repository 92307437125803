.about__bg-mv{
    background-color: #7abf26;
    color: whitesmoke;
    width: 100%;
    height: 100%;
}

.about__bg-pbo{
    background-color: #e06234;
    color: whitesmoke;
}

.about__bg-logo-pbo {
    background-image: url('../../assets/logos/logo_pbo_about.png');
    background-size: cover;
    width: 395px; 
    height: 195px;
}

.about__title{
    color: #e06234;
    font-size: 50px;
}



.about__bg-image {
    background-image: url('../../assets/imgs/bg-about-us.jpg');
    background-size: cover;
}

