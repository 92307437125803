// Nomenclatura para las clases de los archivos: nombrearchivo__clase
// En este archivo solo se importan los demas archivos
// En el archivo base van todos los estilos que se desean aplicar para la web completa
// En el settings van todas las configuraciones de variables, etc.


@import './base/settings';
@import './base/base';


@import './components/home';
@import './components/contact';
@import './components/events';
@import './components/programs';
@import './components/about';

@import './components/footer';
@import './components/navbar';


@import './components/gallery';

/* CSS para hacer el iframe responsivo */
.iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* Relación de aspecto 16:9 */
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

/* Media Queries para diferentes tamaños de dispositivos */
@media only screen and (max-width: 600px) {
    .iframe-container {
        padding-top: 75%; /* Ajustando la relación de aspecto para dispositivos más pequeños */
    }
}
